/* @Poli – here are the global styles */
@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,700&display=swap');

@font-face {
  font-family: 'Berlingske';
  src: url('_/fonts/Berlingske_ExtraBold.woff');
}

*,
*:before,
*:after {
  box-sizing: border-box;
  user-select: none;
  scrollbar-color: #979797 white; /*Firefox Scrollbar;*/
  scrollbar-width: thin; /*Firefox Scrollbar;*/
}

/*Chrome/Webkit Scrollbar*/
*::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 1vw;
}

*::-webkit-scrollbar {
    width: 0.5vh;
    height: 0.7vw;
    background-color: white;
    border-radius: 1vw;
}

*::-webkit-scrollbar-thumb {
    background-color: #979797;
    width: 10px;
    border-radius: 1vw;
}

h1,
h2,
h3,
h4,
h5,
h6 { font: inherit; }

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Work Sans', sans-serif;

  /* @Poli – edit the line below to achieve more scalable (first .5) or static (second one) font-size. The sum of factors should be 1.
    - first .5 – scaling factor
    - 1.6rem – base font-size
    – second .5 – static factor
    – 16 / 1440 * 100vw – relation of basic font-size to viewport width
   */
  font-size: calc(.5 * 1.6rem + .5 * 16 / 1540 * 100vw);
  overflow-x: hidden

}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0
}

button {
  padding: 0;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  outline: none;
}

a {
  color: inherit;
  font: inherit;
  text-decoration: none;
  user-select: auto;
}

p {
  margin: 0;
}

figure {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

legend {
  padding: 0;
}

.label {
  letter-spacing: .15em;
  text-transform: uppercase;
}

.square-button {
  display: inline-block;
  min-width: 14em;
  padding: 1em 3em;
  background-color: #09341D;
  color: #fff;
  font-size: 87.5%;
}
.serif{
  font-family: 'Berlingske';
}
.red{
  color: #ee4322
}
.white{
  color: #fff;
}
.line{
  letter-spacing: 1px;
}
.show{
  display: block
}
.hide{
  display: none !important;
}
.bold{
  font-weight: bold;
}
.align-left{
  text-align: left;
}
.align-right{
  text-align: right;
}
.button{
  padding: .25em 1.25em;
  border: 1px solid #cccccc;
  border-radius: 50vh;
  display: inline-block;
  cursor: pointer;
  background-color: white;
}
.button.active{
  color: white;
  font-weight: bold;
  background-color: #ee4322;
  border: none;
}
/*.filters-section:last-child .filter:last-child:after{
  content:'⌄';
  font-weight: bold;
  position: absolute;
  right: 0;
  margin-right: 1em;
  top: 0;
  margin-top: .1em
}
.filters-section:last-child .filter.is-active:last-child:after{
  content:'';
}*/
@media screen and (max-width: 768px) {
  body {
    font-size: calc(.9 * 1.6rem + .1 * 16 / 375 * 100vw);
  }
}
@media screen and (min-width: 768px) and (max-width: 1440px) {
  body{
    font-size: calc(.4 * 1.6rem + .5 * 16 / 1540 * 100vw);
  }
}

@keyframes appear {
  from { opacity: 0; }
}

@keyframes up {
  from { transform: translateY(.3em); }
}

@keyframes appear-scale-down {
  from { opacity: 1; transform: scale(1.2); }
}

@keyframes progress {
  to { stroke-dashoffset: 0 }
}

@-webkit-keyframes fadeout {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@-moz-keyframes fadeout {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@-o-keyframes fadeout {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes fadeout {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
